import { Input, InputGroup, InputLeftElement, Stack } from "@chakra-ui/react";
import React from "react";
import { FaPhoneAlt } from "react-icons/fa";
import { GiConfirmed } from "react-icons/gi";
import { RiLockPasswordFill } from "react-icons/ri";
import { FaMoneyCheck } from "react-icons/fa";
import { FaThumbsUp } from "react-icons/fa";
import { FaUser } from "react-icons/fa";
import { IoKeypad } from "react-icons/io5";

function SignUpForm({ formData, handleChange }) {
  return (
    <Stack spacing={4}>
      <InputGroup>
        <InputLeftElement pointerEvents="none">
          <FaUser color="gray.300" />
        </InputLeftElement>
        <Input
          placeholder="User Name"
          bg={"gray.100"}
          border={0}
          color={"gray.500"}
          _placeholder={{
            color: "gray.500",
          }}
          name={"username"}
          value={formData.username}
          onChange={handleChange}
        />
      </InputGroup>
      <InputGroup>
        <InputLeftElement pointerEvents="none">
          <FaPhoneAlt color="gray.300" />
        </InputLeftElement>
        <Input
          bg={"gray.100"}
          type="tel"
          border={0}
          placeholder="Phone number"
          name={"number"}
          value={formData.number}
          onChange={handleChange}
        />
      </InputGroup>
      <InputGroup>
        <InputLeftElement pointerEvents="none">
          <RiLockPasswordFill color="gray.300" />
        </InputLeftElement>
        <Input
          bg={"gray.100"}
          type="password"
          border={0}
          placeholder="Login Password"
          name={"password"}
          value={formData.password}
          onChange={handleChange}
        />
      </InputGroup>
      <InputGroup>
        <InputLeftElement pointerEvents="none">
          <GiConfirmed color="gray.300" />
        </InputLeftElement>
        <Input
          bg={"gray.100"}
          type="password"
          border={0}
          placeholder="Confirm Password"
          name={"confirm_password"}
          value={formData.confirm_password}
          onChange={handleChange}
        />
      </InputGroup>
      <InputGroup>
        <InputLeftElement pointerEvents="none">
          <FaMoneyCheck color="gray.300" />
        </InputLeftElement>
        <Input
          bg={"gray.100"}
          type="password"
          border={0}
          placeholder="Withdraw Password"
          name={"withdrawal_password"}
          value={formData.withdrawal_password}
          onChange={handleChange}
        />
      </InputGroup>
      <InputGroup>
        <InputLeftElement pointerEvents="none">
          <FaThumbsUp color="gray.300" />
        </InputLeftElement>
        <Input
          bg={"gray.100"}
          type="password"
          border={0}
          placeholder="Confirm Password"
          name={"confirm_withdrawal_password"}
          value={formData.confirm_withdrawal_password}
          onChange={handleChange}
        />
      </InputGroup>
      <InputGroup>
        <InputLeftElement pointerEvents="none">
          <IoKeypad color="gray.300" />
        </InputLeftElement>
        <Input
          bg={"gray.100"}
          type="text"
          border={0}
          placeholder="Invite Code"
          name={"invitationCode"}
          value={formData.invitationCode}
          onChange={handleChange}
        />
      </InputGroup>
    </Stack>
  );
}

export default SignUpForm;
