import {
  IconButton,
  Avatar,
  Box,
  CloseButton,
  Flex,
  HStack,
  VStack,
  Icon,
  useColorModeValue,
  Text,
  Drawer,
  DrawerContent,
  useDisclosure,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import { FiMenu, FiChevronDown } from "react-icons/fi";
import { FaAddressCard, FaWallet } from "react-icons/fa6";
import { FaHistory } from "react-icons/fa";
import { HiHome } from "react-icons/hi2";
import { HiTrendingUp } from "react-icons/hi";
import { Link, useLocation } from "react-router-dom";
import { MdCardMembership } from "react-icons/md";
import { PiCertificateFill } from "react-icons/pi";
import { AiFillMessage } from "react-icons/ai";
import { BsFillInfoCircleFill } from "react-icons/bs";
import Logo from '../../assets/logo.png';

const LinkItems = [
  { name: "Home", icon: HiHome, link: "/user/home" },
  { name: "Start your career", icon: HiTrendingUp, link: "/user/orders" },
  { name: "History", icon: FaHistory, link: "/user/order-history" },
  { name: "Withdraw", icon: FaWallet, link: "/user/withdraw" },
  { name: "Wallet info", icon: FaAddressCard, link: "/user/wallet-info" },
  // { name: "", icon: , link: "/user/" },
  {
    name: "About",
    icon: BsFillInfoCircleFill,
    link: "/user/about",
    isBelow: true,
  },
  {
    name: "Membership",
    icon: MdCardMembership,
    link: "/user/membership",
    isBelow: true,
  },
  {
    name: "Certificate",
    icon: PiCertificateFill,
    link: "/user/certificate",
    isBelow: true,
  },
  {
    name: "Get In Touch",
    icon: AiFillMessage,
    link: "/user/GetInTouch",
    isBelow: true,
  },
  {
    name: "Terms & Conditions",
    icon: AiFillMessage,
    link: "/user/T&C",
    isBelow: true,
  },
];

const SidebarContent = ({ onClose, ...rest }) => {
  return (
    <Box
      transition="3s ease"
      // bg={useColorModeValue("white", "gray.900")}
      borderRight="1px"
      borderRightColor={useColorModeValue("gray.200", "gray.700")}
      w={{ base: "full", md: 60 }}
      pos="fixed"
      bg={"#100124"}
      h={"full"}
      {...rest}
    >
      <Flex h="20" alignItems="center" mx="8" justifyContent="space-between">
        <text>Logo</text>
        <CloseButton display={{ base: "flex", md: "none" }} onClick={onClose} />
      </Flex>
      {console.log(window.location.pathname)}
      {LinkItems.map((link, i) => (
        <Link to={link.link} key={i}>
          <NavItem
            key={link.name}
            icon={link.icon}
            color={"white"}
            link={link.link}
          >
            {window.location.pathname === link.link ? (
              <Text color={"gray.900"} fontWeight={"4xl"}>{link.name}</Text>
            ) : (
              <Text color={"white"}>{link.name}</Text>
            )}
          </NavItem>
        </Link>
      ))}
    </Box>
  );
};

const NavItem = ({ icon, children, link, ...rest }) => {
  return (
    <Box
      as="a"
      href="#"
      style={{ textDecoration: "none" }}
      _focus={{ boxShadow: "none" }}
    >
      {window.location.pathname === link ? (
        <Flex
          align="center"
          p="4"
          mx="4"
          borderRadius="lg"
          role="group"
          cursor="pointer"
          bg="yellow.500"
          color="blue.700"
          boxShadow={"50"}
          {...rest}
        >
          {icon && (
            <Icon
              mr="4"
              fontSize="16"
              color = "gray.900"
              as={icon}
            />
          )}
          {children}
        </Flex>
      ) : (
        <Flex
          align="center"
          p="4"
          mx="4"
          borderRadius="lg"
          role="group"
          cursor="pointer"
          _hover={{
            bg: "gray.400",
            color: "gray.900",
          }}
          {...rest}
        >
          {icon && (
            <Icon
              mr="4"
              fontSize="16"
              _groupHover={{
                color: "gray.900",
              }}
              as={icon}
            />
          )}
          {children}
        </Flex>
      )}
    </Box>
  );
};

const MobileNav = ({ onOpen, ...rest }) => {
  function signOut() {
    localStorage.removeItem("token");
    localStorage.removeItem("userName");
    window.location = "/";
  }
  return (
    
    <Flex
      ml={{ base: 0, md: 60 }}
      px={{ base: 4, md: 4 }}
      height="20"
      alignItems="center"
      // bg={useColorModeValue("white", "gray.900")}
      borderBottomWidth="1px"
      borderBottomColor={useColorModeValue("gray.200", "gray.700")}
      justifyContent={{ base: "space-between", md: "flex-end" }}
      {...rest}
      bg={"#100124"}
    >
<Avatar
                  size={"large"}
                  src={Logo}
                  />
      <IconButton
        display={{ base: "flex", md: "none" }}
        onClick={onOpen}
        variant="outline"
        aria-label="open menu"
        icon={<FiMenu />}
      />

      <Text
        display={{ base: "flex", md: "none" }}
        fontSize="2xl"
        fontFamily="monospace"
        fontWeight="bold"
      >
        Logo
      </Text>

      <HStack spacing={{ base: "0", md: "6" }}>
        <Flex alignItems={"center"}>
          <Menu>
            <MenuButton
              py={2}
              transition="all 0.3s"
              _focus={{ boxShadow: "none" }}
            >
              <HStack>
                <Avatar
                  size={"sm"}
                  src={
                    "https://images.unsplash.com/photo-1619946794135-5bc917a27793?ixlib=rb-0.3.5&q=80&fm=jpg&crop=faces&fit=crop&h=200&w=200&s=b616b2c5b373a80ffc9636ba24f7a4a9"
                  }
                />
                <VStack
                  display={{ base: "none", md: "flex" }}
                  alignItems="flex-start"
                  spacing="1px"
                  ml="2"
                  color={"white"}
                >
                  <Text fontSize="sm">
                    {localStorage.getItem("userName") || ""}
                  </Text>
                </VStack>
                <Box display={{ base: "none", md: "flex" }}>
                  <FiChevronDown color="gray.300" />
                </Box>
              </HStack>
            </MenuButton>
            <MenuList
              bg={useColorModeValue("white", "gray.900")}
              borderColor={useColorModeValue("gray.200", "gray.700")}
            >
              <Link to="/user/profile">
                <MenuItem>Profile</MenuItem>
              </Link>
              <MenuDivider />
              <MenuItem onClick={signOut}>Sign out</MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </HStack>
    </Flex>
  );
};

const SidebarWithHeader = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { pathname } = useLocation();
  return (
    // <Box bg={useColorModeValue("black.100", "gray.700")}>
    <Box bg={"gray.700"}>
      <SidebarContent
        onClose={() => onClose}
        display={{ base: "none", md: "block" }}
      />
      <Drawer
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full"
      >
        <DrawerContent>
          <SidebarContent onClose={onClose} />
        </DrawerContent>
      </Drawer>
      {/* mobilenav */}
      <MobileNav onOpen={onOpen} />
      <Box ml={{ base: 0, md: 60 }}>{/* Content */}</Box>
    </Box>
  );
};

export default SidebarWithHeader;
