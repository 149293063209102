import React, { useEffect } from "react";
import Footer from "../shared/footer/index";
import { Navigate, Outlet } from "react-router-dom";
import { Box } from "@chakra-ui/react";
import { isAuthToken } from "../api/config";

function UserAuthLayout() {
  useEffect(()=>{
    if(isAuthToken()){
      <Navigate to = '/home'/>
    }
   },[]) 
  return (
    <>
    <Box minH={"100vh"}> 
      <Outlet />
      {/* <Footer /> */}
      </Box>
    </>
  );
}

export default UserAuthLayout;
