import axiosInstance, { setAuthToken } from "./config";

export const signUp = async (formData) => {
  try {
    const {
      username,
      password,
      confirmPassword,
      withdrawal_password,
      number,
      invitationCode,
    } = formData;
    const res = await axiosInstance.post("/users/sign-up", {
      username,
      password,
      confirmPassword,
      withdrawal_password,
      number,
      invitationCode,
      first_name: username.split(" ")[0],
      last_name: username.split(" ")[1],
    });
    setAuthToken(res.data?.token,res.data?.username)
    return res;
  } catch (error) {
    console.error(error.response.data);
    throw error.response.data;
  }
};
