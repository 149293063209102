"use client";

import {
  VStack,
  Image,
  Box,
  Flex,
  Container,
  Heading,
  Stack,
  Text,
  Button,
} from "@chakra-ui/react";
import ContactImg from "../../assets/contactUs.png";

export default function CallToActionWithIllustration() {
  return (
    <Container maxW="5xl" maxH={"100vh"}>
      <Stack
        textAlign="center"
        align="center"
        spacing={{ base: 8, md: 10 }}
        py={{ base: 10, md: 18 }}
      >
        <Heading
          fontWeight={400}
          fontSize={{ base: "3xl", sm: "3xl", md: "4xl" }}
          lineHeight="110%"
          color={"gray.300"}
        >
          Meeting scheduling{" "}
          <Text as="span" color="orange.400">
            made easy
          </Text>
        </Heading>
        <Box borderRadius="lg" overflow="hidden">
          <Box textDecoration="none" _hover={{ textDecoration: "none" }}>
            <Image
              transform="scale(1.0)"
              src={ContactImg}
              alt="some text"
              objectFit="contain"
              height={"sm"}
              marginLeft={"20"}
              width="80%"
              transition="0.3s ease-in-out"
              _hover={{
                transform: "scale(1.05)",
              }}
            />
          </Box>
        </Box>
        <VStack paddingTop="0px" spacing="0" alignItems="flex-start">
          <Text as="p" fontSize="lg" color={"white"}>
            If you have any questions, please contact our Live Support or go to
            WhatsApp for a consultation!
          </Text>
        </VStack>
        <Stack spacing={6} direction="row">
          <Button
            rounded="full"
            px={6}
            colorScheme="orange"
            bg="orange.400"
            _hover={{ bg: "orange.500" }}
          >
            Live Chat
          </Button>
          <Button rounded="full" px={6} bg="blue.300" color={"white"}>
            Telegram
          </Button>
        </Stack>
        <Flex w="full"></Flex>
      </Stack>
    </Container>
  );
}
