import { RouterProvider } from "react-router-dom";
import AllRoutes from "./routes";

function App() {
  return (
    <>
      <RouterProvider router={AllRoutes} />
    </>
  );
}

export default App;
