import axiosInstance, { setAuthToken } from "./config";

export const login = async (userName, password) => {
  try {
    const res = await axiosInstance.post("/users/sign-in", {
      username:userName,
      password,
    });
    setAuthToken(res.data?.token,res.data?.username)
    return res;
  } catch (error) {
    console.error(error.response.data);
    throw error.response.data;
  }
};
