import React from "react";
import MovieDetailCard from "./movieDetailCard";
import { Box, SimpleGrid } from "@chakra-ui/react";

function allOrders({ data }) {
  return (
    <SimpleGrid minChildWidth="240px" spacing="2em">
      {data.length > 0 &&
        data.map((e, i) => (
          <Box>
            <MovieDetailCard key={i} data={e}/>
          </Box>
        ))}
    </SimpleGrid>
  );
}

export default allOrders;
