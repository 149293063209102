import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: 'https://api.hollymovies.org/api', 
});

export const setAuthToken = (token, userName) => {
  if (token) {
    axiosInstance.defaults.headers.common['authorization'] = `Bearer ${token}`;
    localStorage.setItem("token", token)
    localStorage.setItem("userName", userName)
  }
  console.log(axiosInstance.defaults.headers.common['authorization'])
};
export const isAuthToken = () => {
  if (axiosInstance.defaults.headers.common?.authorization) {
    console.log("Auth ", axiosInstance.defaults.headers.common?.authorization)
    return true
  } else if (localStorage.getItem("token")) {
    setAuthToken(localStorage.getItem("token"), localStorage.getItem("userName"))
    return true
  }
  return false
};

export default axiosInstance;
