import { Box, Divider, Heading, HStack, List, ListItem, Stack, Text, useColorModeValue } from '@chakra-ui/react';
import React from 'react'

function NegativeBalance({totalProfit,totalBalance}) {
  return (
    <>
      <Box as={"header"}>
        <Heading
          lineHeight={1.1}
          fontWeight={600}
          fontSize={{ base: "2xl", sm: "4xl", lg: "5xl" }}
        >
          {"Account Details"}
        </Heading>
      <Divider borderColor={useColorModeValue("gray.200", "gray.600")} mt={4} mb={-5} size={"full"} />
      </Box>
      <Stack
        spacing={{ base: 4, sm: 6 }}
        direction={"column"}
      >
        <HStack spacing={20}>
          <Box>
            <Text
              fontSize={{ base: "16px", lg: "18px" }}
              color={useColorModeValue("black.500", "yellow.300")}
              fontWeight={"bold"}
              textTransform={"uppercase"}
              mb={"4"}
            >
              Account Balance
            </Text>

            <List spacing={2}>
              <ListItem>
                <HStack spacing={5}>
                  <Text as={"span"} fontWeight={"bold"}>
                    Total Profit (USDC)
                  </Text>{" "}
                  <Text as={"span"} fontWeight={""}>
                  { totalProfit || "0.00"}
                  </Text>
                </HStack>
              </ListItem>
              <ListItem>
                <HStack spacing={5}>
                  <Text as={"span"} fontWeight={"bold"}>
                    Account Balance(USDC)
                  </Text>{" "}
                  <Text as={"span"} fontWeight={""} color={"red"}>
                  { totalBalance || "0.00"}
                  </Text>
                </HStack>
              </ListItem>
              <ListItem>
                <HStack spacing={5}>
                  <Text as={"span"} fontWeight={"bold"} color={"red"}>
                    You Have Insufficent Amount. Contact Support Agent For Further Details. 
                  </Text>{" "}
                </HStack>
              </ListItem>
            </List>
          </Box>
        </HStack>
      </Stack>
    </>
  )
}

export default NegativeBalance;