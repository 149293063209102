import { Box, Container, VStack } from "@chakra-ui/react";
import React from "react";
import VideoPlayer from "./components/videoPlay";
import MovieListing from "./components/movieListing";
import DS from "../../assets/doctor Strange(multiverse of madness).jpg";
import jw from "../../assets/jhon wick.jpg";
import sw from "../../assets/star wars.jpg";
import Dp from "../../assets/Deadpool.jpg";
import iw from "../../assets/avengers infinity war.jpg";
import bp from "../../assets/black panther.jpg";
import av from "../../assets/avatar.jpg";
import ss from '../../assets/suicide squad.jpg';
import fc from '../../assets/fight club.jpg';
import bh from '../../assets/braveheart.jpg';
import re from '../../assets/the revenant.jpg';



function index() {
  let data = [{image:DS,name:"Doctor Strange",price:"40 USDC"},{image:jw,name:"Jhon Wick",price:"100 USDC"}, {image:sw,name:"Star wars",price:"60 USDC"},{image:Dp,name:"Dead Pool",price:"45 USDC"},{image:iw,name:"Infinity War",price:"100 USDC"},{image:bp,name:"Black Panther",price:"100 USDC"}];
  let hotpicks = [{image:av,name:"Avatar",price:"200 USDC"},{image:DS,name:"Doctor Strange",price:"40 USDC"},{image:ss,name:"Suicide Squad",price:"65 USDC"},{image:fc,name:"Fight Club",price:"65 USDC"},{image:bh,name:"Brave Heart",price:"95 USDC"},{image:re,name:"The Revenant",price:"80 USDC"}];
  return (
    <Box>
      <VStack align={"flex-start"}>
        <VideoPlayer />
        <MovieListing data={data} heading={"Trending"} />
        <MovieListing data={hotpicks} heading={"Hot Picks"} />
      </VStack>
    </Box>
  );
}

export default index;
