import React, { useState } from "react";
import { Box, Container, Flex } from "@chakra-ui/react";

const VideoPlayer = () => {
  const [videoUrl, setVideoUrl] = useState(false);
  return (
    <Container width={"100%"} alignContent={"center"}>
      <video controls width={"100%"}>
        <source
          src={"https://www.youtube.com/watch?v=ysz5S6PUM-U"}
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
    </Container>
  );
};

export default VideoPlayer;
