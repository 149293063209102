'use client'

import {
  Box,
  Heading,
  Image,
  Text,
  Divider,
  HStack,
  Tag,
  Wrap,
  WrapItem,
  useColorModeValue,
  Container,
  VStack,
} from '@chakra-ui/react';

import certificate from '../../assets/Certificate.jpg';

const BlogTags = (props) => {
  const { marginTop = 0, tags } = props

  return (
    <HStack spacing={2} marginTop={marginTop}>
      {tags.map((tag) => {
        return (
          <Tag size={'md'} variant="solid" colorScheme="orange" key={tag}>
            {tag}
          </Tag>
        )
      })}
    </HStack>
  )
}

const BlogAuthor = (props) => {
  return (
    <HStack marginTop="2" spacing="2" display="flex" alignItems="center">
      <Image
        borderRadius="full"
        boxSize="40px"
        src="https://100k-faces.glitch.me/random-image"
        alt={`Avatar of ${props.name}`}
      />
      <Text fontWeight="medium">{props.name}</Text>
      <Text>—</Text>
      <Text>{props.date.toLocaleDateString()}</Text>
    </HStack>
  )
}

const ArticleList = () => {
  return (
    <Container maxW={'7xl'} p="12">
      <Heading as="h1" >Certificate</Heading>
      <Box
        marginTop={{ base: '1', sm: '5' }}
        display="flex"
        flexDirection={{ base: 'column', sm: 'row' }}
        justifyContent="space-between">
        
      </Box>
      
      <Divider marginTop="5" />
      <Wrap spacing="30px" marginTop="5">
        <WrapItem width={{ base: '100%', sm: '45%', md: '45%', lg: '80%' }}>
          <Box w="100%">
            <Box borderRadius="lg" overflow="hidden">
              <Box textDecoration="none" _hover={{ textDecoration: 'none' }}>
                <Image
                  transform="scale(1.0)"
                  src={certificate}
                  alt="some text"
                  objectFit="contain"
                  width="100%"
                  transition="0.3s ease-in-out"
                  _hover={{
                    transform: 'scale(1.05)',
                  }}
                />
              </Box>
            </Box>
          </Box>
        </WrapItem>
      </Wrap>
      <VStack paddingTop="40px" spacing="2" alignItems="flex-start">
        <Heading as="h2" >Authorization for Rating Movies to Operate in Canada and USA </Heading>
        <Text as="p" fontSize="lg" color={'white'}>
        This authorization certifies that Holly Movies.inc is authorized to operate and conduct business activities in both Canada and the 
        United States of America.
        </Text>
        <Text as="p" fontSize="lg" color={'white'}>
        This authorization grants full permission to rating movies to engage in all lawful activities necessary for its operation within the 
        territories of Canada and the USA.The scope of authorization includes,but is not limited to, marketing, sales,partnerships, customer 
        support, and any other activities essential for the functioning of rating movies services
        </Text>
        <Text as="p" fontSize="lg" color={'white'}>
        I(Linda Bartone) confirm that Rating Movies is a duly registered entity and has met all legal and regulatory requirements to operate in 
        both Canada and the USA. This authorization is effective from the date of this letter and remains valid until 1st January 2029.
        </Text>
        <Text as="p" fontSize="lg" color={'white'}>
        Should you require any further information or verification regarding this authorization,please do not hesitate to 
        contact us with Customer Service of the platform.
        </Text>
        <Text as="p" fontSize="lg" color={'white'}>
        Holly Movies.INC upholds the principles of an Equal Opportunity Employer. We are dedicated to ensuring that all qualified applicants 
        receive equitable consideration for employment, irrespective of race, color, religion, sex, sexual orientation, gender identity, 
        gender expression, national origin, protected veteran status, or any other basis protected by applicable law. Discrimination based on 
        disability is strictly prohibited according to our Equal Employment Opportunity Policy.
        </Text>
        <Text as="p" fontSize="lg" color={'white'}>
        Furthermore, we demonstrate our unwavering commitment to fostering an 
        inclusive workplace. We are fully prepared to collaborate with and offer reasonable support to individuals with 
        physical and mental disabilities through our Accommodation Request policy.
        </Text>
        <Text as="p" fontSize="lg" color={'white'}>
        Additionally, we emphasize the importance of protecting our platform from unauthorized
         replication. Hereby we inform that, in the event of any such attempt, legal action will be taken.
        </Text>
      </VStack>
    </Container>
  )
}

export default ArticleList
