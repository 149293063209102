import {
  Box,
  Divider,
  Heading,
  HStack,
  List,
  ListItem,
  Stack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import React from "react";

function ProductListing(props) {
  const {
    movie_description,
    movie_image,
    movie_name,
    movie_price,
    rating,
  } = props.data;
  return (
    <>
      <Box as={"header"}>
        <Heading
          lineHeight={1.1}
          fontWeight={600}
          fontSize={{ base: "2xl", sm: "4xl", lg: "5xl" }}
          color={useColorModeValue("yellow.500", "yellow.300")}
        >
          {movie_name}
        </Heading>
        <Text
          color={"gray.300"}
          fontWeight={300}
          fontSize={"2xl"}
        >
          {`${movie_price} (USDC)`}
        </Text>
        <Divider
          borderColor={useColorModeValue("gray.200", "gray.600")}
          mt={4}
          mb={-5}
          size={"full"}
        />
      </Box>
      <Stack spacing={{ base: 4, sm: 6 }} direction={"column"}>
        <HStack spacing={20}>
          <Box>
            <Text
              fontSize={{ base: "16px", lg: "18px" }}
              color={useColorModeValue("yellow.500", "yellow.300")}
              fontWeight={"bold"}
              textTransform={"uppercase"}
              mb={"4"}
            >
              Product Details
            </Text>

            <List spacing={2}>
            <ListItem>
                <Text as={"span"} fontWeight={"bold"}>
                  Movie Price:
                </Text>{" "}
                {`${movie_price} (USDC)`}
              </ListItem>
              <ListItem>
                <Text as={"span"} fontWeight={"bold"}>
                  Description:
                </Text>{" "}
                {movie_description}
              </ListItem>
              <ListItem>
                <Text as={"span"} fontWeight={"bold"}>
                  Commision Amount:
                </Text>{" "}
                {`${rating.commission_amount} (USDC)`}
              </ListItem>
              
            </List>
          </Box>
        </HStack>
      </Stack>
    </>
  );
}

export default ProductListing;
