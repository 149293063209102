import {
  Box,
  Stack,
  Heading,
  Text,
  Container,
  Input,
  Button,
  SimpleGrid,
  useBreakpointValue,
  Icon,
  InputGroup,
  InputLeftElement,
  Image,
  Checkbox,
  useToast,
} from "@chakra-ui/react";

import image from "../../assets/download.png";

import { RiLockPasswordFill } from "react-icons/ri";
import { FaUser } from "react-icons/fa";
import { useState } from "react";
import { login } from "../../api/login";
import { Link, useNavigate } from "react-router-dom";
// import Toast from "../../shared/toast/Toast";

const Blur = (props) => {
  return (
    <Icon
      width={useBreakpointValue({ base: "100%", md: "40vw", lg: "30vw" })}
      zIndex={useBreakpointValue({ base: -1, md: -1, lg: 0 })}
      height="560px"
      viewBox="0 0 528 560"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="71" cy="61" r="111" fill="#F56565" />
      <circle cx="244" cy="106" r="139" fill="#ED64A6" />
      <circle cy="291" r="139" fill="#ED64A6" />
      <circle cx="80.5" cy="189.5" r="101.5" fill="#ED8936" />
      <circle cx="196.5" cy="317.5" r="101.5" fill="#ECC94B" />
      <circle cx="70.5" cy="458.5" r="101.5" fill="#48BB78" />
      <circle cx="426.5" cy="-0.5" r="101.5" fill="#4299E1" />
    </Icon>
  );
};

export default function JoinOurTeam() {
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const toast = useToast();

  async function handleSubmit() {
    try {
      setLoading(true);
      const res = await login(userName, password);
      setLoading(false);
      if (res.status === 200) {
        navigate("/user/home");
      } else {
        toast({
          title: "Login Failed",
          description: "Invalid user name or password",
          status: "error",
          position: "top",
        });
      }
    } catch (error) {
      setLoading(false);
      toast({
        title: "Login Failed",
        description: error.message,
        status: "error",
        position: "top",
      });
    }
  }

  return (
    <>
      <Box position={"relative"} background={"gray.700"} minH={"100vh"}>
        <Container
          as={SimpleGrid}
          maxW={"7xl"}
          columns={{ base: 1, md: 2 }}
          spacing={{ base: 10, lg: 32 }}
          py={{ base: 8, sm: 18, lg: 28 }}
        >
          <Stack spacing={{ base: null, md: 20 }}>
            <Heading
              lineHeight={1.1}
              fontSize={{ base: "3xl", sm: "4xl", md: "5xl", lg: "6xl" }}
              color={"gray.50"}
              // zIndex={10}
            >
              Watch Real Movies{"  "}
              <Text
                as={"span"}
                bgGradient="linear(to-r, red.400,pink.400)"
                bgClip="text"
              >
                &
              </Text>{" "}
              Earn By Ratings Them
            </Heading>
            <Image src={image} boxSize="100px"></Image>
          </Stack>
          <Stack
            bg={"gray.50"}
            rounded={"xl"}
            p={{ base: 4, sm: 6, md: 8 }}
            spacing={{ base: 8 }}
            maxW={{ lg: "lg" }}
          >
            <Stack spacing={4}>
              <Heading
                color={"gray.800"}
                lineHeight={1.1}
                fontSize={{ base: "2xl", sm: "3xl", md: "4xl" }}
              >
                Welcome Back
                <Text
                  as={"span"}
                  bgGradient="linear(to-r, red.400,pink.400)"
                  bgClip="text"
                >
                  {" "}
                  !
                </Text>
                <Text
                  mt={4}
                  color={"gray.500"}
                  fontSize={{ base: "sm", sm: "lg" }}
                >
                  Login into your account
                </Text>
              </Heading>
            </Stack>
            <Box as={"form"} mt={3}>
              <Stack spacing={4}>
                <InputGroup>
                  <InputLeftElement pointerEvents="none">
                    <FaUser color="gray.300" />
                  </InputLeftElement>
                  <Input
                    placeholder="User Name"
                    bg={"gray.100"}
                    border={0}
                    color={"gray.500"}
                    _placeholder={{
                      color: "gray.500",
                    }}
                    value={userName}
                    onChange={(e) => setUserName(e.target.value)}
                  />
                </InputGroup>

                <InputGroup>
                  <InputLeftElement pointerEvents="none">
                    <RiLockPasswordFill color="gray.300" />
                  </InputLeftElement>
                  <Input
                    bg={"gray.100"}
                    type="password"
                    border={0}
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </InputGroup>
                <Stack
                  direction={{ base: "row", sm: "row" }}
                  align={"start"}
                  justify={"space-between"}
                >
                  <Text>
                    Don't have an account ?{" "}
                    <Link to="sign-up">
                      <Text display={"inline"} color={"blue.500"}>Sign-up</Text>
                    </Link>
                  </Text>
                </Stack>
              </Stack>
              <Button
                fontFamily={"heading"}
                mt={8}
                w={"full"}
                bg={"blue.900"}
                color={"gray.50"}
                _hover={{
                  bg: "gray.300",
                  color: "blue.900",
                  boxShadow: "x2",
                }}
                onClick={handleSubmit}
                isLoading={loading}
              >
                Login
              </Button>
            </Box>
          </Stack>
        </Container>
        <Blur
          position={"absolute"}
          top={-10}
          left={-10}
          style={{ filter: "blur(70px)" }}
        />
      </Box>
    </>
  );
}
