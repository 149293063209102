import axiosInstance from "./config";

export const orderHistory = async () => {
  try {
    const res = await axiosInstance.get("/ratings/history",{customer_id:16});
    return res.data;
  } catch (error) {
    console.error(error.response?.data || "Something Went Wrong");
    throw error.response?.data || "Something Went Wrong";
  }
};

export const rateOrder = async (comment,rating) => {
  try {
    const res = await axiosInstance.post("/ratings/rate",{comment,user_rating:rating});
    return res;
  } catch (error) {
    console.error(error.response?.data || "Something Went Wrong");
    throw error.response?.data || "Something Went Wrong";
  }
};

export const buyOrder = async () => {
  try {
    const res = await axiosInstance.post("/ratings/buy",{});
    return res;
  } catch (error) {
    console.error(error.response?.data || "Something Went Wrong");
    throw error.response?.data || "Something Went Wrong";
  }
};

export const generateOrder = async () => {
  try {
    const res = await axiosInstance.get("/ratings/generate",{customer_id:15});
    return res;
  } catch (error) {
    console.error(error.response?.data || "Something Went Wrong");
    throw error.response?.data || "Something Went Wrong";
  }
};

export const getOrderInfo = async () => {
  try {
    const res = await axiosInstance.get("/ratings/info");
    console.log(res)
    return res.data;
  } catch (error) {
    console.log(error.response?.data || "Something Went Wrong");
    throw error.response?.data || "Something Went Wrong";
  }
};

