import axiosInstance from "./config";

export const getUserProfile = async () => {
    try {
      const res = await axiosInstance.get("/users/get-profile");
      console.log(res.data)
      return res.data;
    } catch (error) {
      console.log(error.response?.data|| "Something Went Wrong");
      throw error.response?.data|| "Something Went Wrong";
    }
  };
export const getWalletInfo = async () => {
    try {
      const res = await axiosInstance.get("/users/get-wallet");
      console.log(res.data)
      return res.data;
    } catch (error) {
      console.log(error.response?.data|| "Something Went Wrong");
      throw error.response?.data|| "Something Went Wrong";
    }
  };
export const setWallletInfo = async (data) => {
    try {
      const res = await axiosInstance.post("/users/update-wallet",{...data});
      return res.data;
    } catch (error) {
      console.log(error.response?.data|| "Something Went Wrong");
      throw error.response?.data|| "Something Went Wrong";
    }
  };
export const withdrawAmount = async (amount,password) => {
    try {
      const res = await axiosInstance.post("/users/withdrawal-request",{amount,password});
      return res.data;
    } catch (error) {
      console.log(error.response?.data|| "Something Went Wrong");
      throw error.response?.data|| "Something Went Wrong";
    }
  };
export const withdrawalHistory = async () => {
    try {
      const res = await axiosInstance.get("/users/get-withdrawal");
      return res.data;
    } catch (error) {
      console.log(error.response?.data|| "Something Went Wrong");
      throw error.response?.data|| "Something Went Wrong";
    }
  };

