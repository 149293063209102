"use client";

import {
  Box,
  Heading,
  Image,
  Text,
  Divider,
  HStack,
  Tag,
  Wrap,
  WrapItem,
  useColorModeValue,
  Container,
  VStack,
} from "@chakra-ui/react";

const ArticleList = () => {
  return (
    <Container maxW={"7xl"} p="12">
      <Heading as="h1" >
        Terms And Conditions
      </Heading>
      <Box
        marginTop={{ base: "1", sm: "5" }}
        display="flex"
        flexDirection={{ base: "column", sm: "row" }}
        justifyContent="space-between"
      ></Box>

      <Divider marginTop="5" />
      <Wrap spacing="30px" marginTop="5">
        <WrapItem width={{ base: "100%", sm: "45%", md: "45%", lg: "80%" }}>
          <Box w="100%">
            <Box borderRadius="lg" overflow="hidden">
              <Box
                textDecoration="none"
                _hover={{ textDecoration: "none" }}
              ></Box>
            </Box>
          </Box>
        </WrapItem>
      </Wrap>
      <Text as="p" fontSize="lg" color={"white"}>
        Please read the following updated Terms and Conditions carefully. By
        using our platform, you agree to abide by these terms. Thank you for
        your cooperation.
      </Text>
      <VStack paddingTop="40px" spacing="2" alignItems="flex-start">
        <Heading as="h5" fontSize="large" >
          1. Account Usage and Withdrawals
        </Heading>
        <Text as="p" fontSize="medium" color={"white"}>
          All Movies must be completed before initiating a withdrawal or
          resetting your account.
        </Text>

        <Heading as="h5" fontSize="large">
          2. Account Registration
        </Heading>
        <Text as="p" fontSize="lg" color={"white"}>
          Only one account is allowed per phone number. Multiple accounts
          registered under the same phone number will be deactivated.
        </Text>

        <Heading as="h5" fontSize="large" >
          3. Wallet Address Binding
        </Heading>
        <Text as="p" fontSize="lg" color={"white"}>
          It is strictly prohibited to bind the same wallet address to different
          accounts. If we detect this activity, we reserve the right to freeze
          the related accounts.
        </Text>

        <Heading as="h5" fontSize="large" >
          4. Account Security
        </Heading>
        <Text as="p" fontSize="lg" color={"white"}>
          Please ensure that you do not disclose your account password and
          withdrawal password to anyone else. Our platform will not be held
          responsible for any damages resulting from unauthorized access to your
          account.
        </Text>

        <Heading as="h5" fontSize="large" >
          6. Inappropriate Use of Account
        </Heading>
        <Text as="p" fontSize="lg" color={"white"}>
          In the event of inappropriate use of your account, we reserve the
          right to take legal action against you.
        </Text>

        <Heading as="h5" fontSize="large" >
          7. Deposit Confirmation
        </Heading>
        <Text as="p" fontSize="lg" color={"white"}>
          Before transferring funds, kindly confirm the deposit address with our
          customer service to ensure accuracy.
        </Text>

        <Heading as="h5" fontSize="large" >
          8. Wrong Deposit Address
        </Heading>
        <Text as="p" fontSize="lg" color={"white"}>
          The platform will not assume any responsibility if a deposit is made
          to the wrong deposit address. Please verify the address before making
          a deposit.
        </Text>

        <Heading as="h5" fontSize="large" >
          9. Minimum Balance Requirement
        </Heading>
        <Text as="p" fontSize="lg" color={"white"}>
          Members must have a minimum balance of USDT 80 before starting the
          ratings. Starting to ratings with a balance below this threshold is
          not permitted.
        </Text>

        <Heading as="h5" fontSize="large" >
          10. Ratings Completion
        </Heading>
        <Text as="p" fontSize="lg" color={"white"}>
          Once you start to rating Movies, it must be completed within one day.
          If you anticipate being unable to complete it within this time-frame,
          please inform our customer service as soon as possible. Dear member,
          please take the time to read and understand our updated Terms and
          Conditions. Your cooperation is greatly appreciated.
        </Text>

        <Text as="p" fontSize="lg" color={"white"} marginTop={"30px"}>
          © 2024 HollyMovies.INC All rights reserved.
        </Text>
      </VStack>
    </Container>
  );
};

export default ArticleList;
