import React, { useEffect, useState } from "react";
import { Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react";
import AllOrders from "./allOrders";
import CompletedOrders from "./completedOrders";
import PendingOrders from "./pendingOrders";
import OnHoldOrder from "./onHoldOrder";
function MainTabs({data}) {
  return (
    <Tabs align={"center"} variant="soft-rounded">
      <TabList mb="2em" mt="2em" color={"gray.300"}>
        <Tab color={"gray.300"}>All</Tab>
        <Tab color={"gray.300"}>Pending</Tab>
        <Tab color={"gray.300"}>Completed</Tab>
        <Tab color={"gray.300"}>On Hold</Tab>
      </TabList>
      <TabPanels>
        <TabPanel>
          <AllOrders data={data} />
        </TabPanel>
        <TabPanel>
          <PendingOrders data={data} />
        </TabPanel>
        <TabPanel>
          <CompletedOrders data={data} />
        </TabPanel>
        <TabPanel>
          <OnHoldOrder data={data} />
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
}

export default MainTabs;
