import { Box, Heading, Text } from "@chakra-ui/react";
import { WarningTwoIcon } from "@chakra-ui/icons";

export default function Warning({ header, desc }) {
  return (
    <Box textAlign="center" py={10} px={6} color={"gray.300"}>
      <WarningTwoIcon boxSize={"50px"} color={"orange.300"} />
      <Heading as="h2" size="xl" mt={6} mb={2} >
        {header}
      </Heading>
      <Text >{desc}</Text>
    </Box>
  );
}
