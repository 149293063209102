"use client";

import {
  Button,
  Center,
  Divider,
  Flex,
  Grid,
  GridItem,
  Heading,
  Image,
  Stack,
  Text,
  useColorModeValue,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { getUserProfile } from "../../api/user";

export default function Profile() {
  const toast = useToast();

  async function userInfo() {
    try {
      setLoading(true);
      getUserProfile().then((data) => {
        setUserProfile(data);
        setLoading(false);
        console.log("userProfile", parseFloat(userProfile.negative_amount));
      });
      // setMovieDetails(getOrderInfoParsing(res));
    } catch (error) {
      console.log(error);
      setLoading(false);
      toast({
        title: "Something Went Wrong",
        description: "Contact Support",
        status: "error",
        position: "top",
      });
    }
  }
  useEffect(() => {
    userInfo();
  }, []);

  const [loading, setLoading] = useState(false);
  const [userProfile, setUserProfile] = useState(false);
  const textColor = useColorModeValue("gray.700", "gray.400");

  return (
    <>
      {!loading && userProfile ? (
        <Center py={6}>
          <Stack
            borderWidth="1px"
            borderRadius="lg"
            w={{ sm: "100%", md: "600px" }}
            height={{ sm: "476px", md: "20rem" }}
            direction={{ base: "column", md: "row" }}
            // eslint-disable-next-line react-hooks/rules-of-hooks
            bg={useColorModeValue("white", "gray.900")}
            boxShadow={"2xl"}
            padding={4}
          >
            <Flex flex={1} bg="blue.200">
              <Image
                width={{ md: "100%", sm: "20%", base: "30%" }}
                objectFit="fill"
                boxSize={"100%"}
                src={
                  "https://images.unsplash.com/photo-1520810627419-35e362c5dc07?ixlib=rb-1.2.1&q=80&fm=jpg&crop=faces&fit=crop&h=200&w=200&ixid=eyJhcHBfaWQiOjE3Nzg0fQ"
                }
                alt="#"
              />
            </Flex>
            <Stack
              flex={1}
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              p={1}
              pt={2}
            >
              <Heading fontSize={"2xl"} fontFamily={"body"}>
                {userProfile.username}
              </Heading>
              <Text
                textAlign={"center"}
                fontWeight={600}
                color={"gray.500"}
                size="sm"
                mb={2}
              >
                {userProfile?.tier?.tier_name || ""}
              </Text>
              <Text
                textAlign={"center"}
                color={textColor}
                px={3}
                fontSize={"12px"}
              >
                Invitation Code : {userProfile.invitationCode}
              </Text>
              <Grid templateColumns="repeat(9, 1fr)" gap={-2}>
                <GridItem colSpan={4}>
                  <VStack textAlign={"center"} color={textColor}>
                    <Text>
                      Daily Profit <br />
                      (USDC)
                    </Text>
                    <Text>{userProfile.currentSession.total_profit}</Text>
                  </VStack>
                </GridItem>
                <GridItem colSpan={1}>
                  <Center height="100%">
                    <Divider orientation="vertical" fontWeight={"1000px"} />
                  </Center>
                </GridItem>
                <GridItem colSpan={4}>
                  <VStack textAlign={"center"} color={parseFloat(userProfile.negative_amount) >= 0 ? textColor : "red"}>
                    <Text>Account Balance (USDC)</Text>
                    <Text>
                      {parseFloat(userProfile.negative_amount) < 0
                        ? parseFloat(userProfile.negative_amount)
                        : userProfile.wallet_amount}
                    </Text>
                  </VStack>
                </GridItem>
              </Grid>
              <Stack
                align={"center"}
                justify={"center"}
                direction={"row"}
              ></Stack>

              <Stack
                width={"85%"}
                mt={"2rem"}
                direction={"row"}
                padding={2}
                justifyContent={"space-between"}
                alignItems={"center"}
              ></Stack>
            </Stack>
          </Stack>
        </Center>
      ) : (
        <></>
      )}
    </>
  );
}
