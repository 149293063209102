"use client";

import {
  Box,
  Heading,
  Image,
  Text,
  Divider,
  HStack,
  Tag,
  Wrap,
  WrapItem,
  useColorModeValue,
  Container,
  VStack,
} from "@chakra-ui/react";

const BlogTags = (props) => {
  const { marginTop = 0, tags } = props;

  return (
    <HStack spacing={2} marginTop={marginTop}>
      {tags.map((tag) => {
        return (
          <Tag size={"md"} variant="solid" colorScheme="orange" key={tag}>
            {tag}
          </Tag>
        );
      })}
    </HStack>
  );
};

const BlogAuthor = (props) => {
  return (
    <HStack marginTop="2" spacing="2" display="flex" alignItems="center">
      <Image
        borderRadius="full"
        boxSize="40px"
        src="https://100k-faces.glitch.me/random-image"
        alt={`Avatar of ${props.name}`}
      />
      <Text fontWeight="medium">{props.name}</Text>
      <Text>—</Text>
      <Text>{props.date.toLocaleDateString()}</Text>
    </HStack>
  );
};

const ArticleList = () => {
  return (
    <Container maxW={"7xl"} p="12">
      <Heading color={useColorModeValue("yellow.500", "yellow.300")} as="h1" >
        About Holly Movies
      </Heading>
      <Box
        marginTop={{ base: "1", sm: "5" }}
        display="flex"
        flexDirection={{ base: "column", sm: "row" }}
        justifyContent="space-between"
      ></Box>

      <Divider marginTop="5" />
      <Wrap spacing="30px" marginTop="5">
        <WrapItem width={{ base: "100%", sm: "45%", md: "45%", lg: "80%" }}>
          <Box w="100%">
            <Box borderRadius="lg" overflow="hidden">
              <Box textDecoration="none" _hover={{ textDecoration: "none" }}>
                {/* <Image
                  transform="scale(1.0)"
                  src={
                    "https://images.unsplash.com/photo-1499951360447-b19be8fe80f5?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=800&q=80"
                  }
                  alt="some text"
                  objectFit="contain"
                  width="md"
                  transition="0.3s ease-in-out"
                  _hover={{
                    transform: "scale(1.05)",
                  }}
                /> */}
              </Box>
            </Box>
          </Box>
        </WrapItem>
      </Wrap>
      <VStack paddingTop="40px" spacing="2" alignItems="flex-start">
        <Heading color={useColorModeValue("yellow.500", "yellow.300")} as="h6" fontSize={"large"} >
          Welcome to Holly Movies, Your Ultimate Movie Rating Destination
        </Heading>
        <Text as="p" fontSize="lg" color={"white"}>
          At Holly Movies, we're passionate about bringing movie lovers together
          through the power of ratings and reviews. Founded with the vision of
          creating a dynamic and user-friendly platform, we provide an inclusive
          space where film enthusiasts can share their opinions, discover hidden
          gems, and make informed decisions about what to watch next.
        </Text>
        <Heading color={useColorModeValue("yellow.500", "yellow.300")} as="h6" fontSize={"large"}>
          Our Mission
        </Heading>
        <Text as="p" fontSize="lg" color={"white"}>
          Our mission is to enhance your cinematic experience by offering
          accurate, diverse, and reliable movie ratings. We believe that every
          film has a unique story to tell and that every viewer's perspective is
          valuable. That's why we strive to create a platform where movie
          ratings are not just numbers but reflections of genuine viewer
          experiences.
        </Text>
        <Heading color={useColorModeValue("yellow.500", "yellow.300")} as="h6" fontSize={"large"} >
          What We Offer
        </Heading>
        <Text as="p" fontSize="lg" color={"white"}>
          • Comprehensive Movie Database: From the latest blockbusters to
          timeless classics, our extensive database covers a wide range of films
          from around the world.
          <br />
          • User Reviews and Ratings: Share your thoughts and see what other
          moviegoers have to say. Our intuitive rating system helps you quickly
          gauge the overall reception of any film.
          <br />
          • Personalized Recommendations: Based on your ratings and preferences,
          we offer tailored suggestions to help you find movies you'll love.
          <br />
          • Community Engagement: Join discussions, participate in forums, and
          connect with fellow members to deepen your appreciation of film.
          <br />
        </Text>
        <Heading color={useColorModeValue("yellow.500", "yellow.300")} as="h6" fontSize={"large"} >
          Join Us
        </Heading>
        <Text as="p" fontSize="lg" color={"white"}>
          Whether you're a casual viewer or a dedicated film buff, Holly movies
          is here to enhance your movie-watching journey. Dive into our
          platform, share your reviews, and discover the films that will
          captivate and entertain you. Together, let’s celebrate the magic of
          cinema!
        </Text>
      </VStack>
    </Container>
  );
};

export default ArticleList;
