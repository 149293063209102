import React from "react";
import MovieDetailCard from "./movieDetailCard";
import { Box, SimpleGrid } from "@chakra-ui/react";
import Warning from "../../../shared/warning/warning";

function completedOrders({ data }) {
  console.log("complete", data);
  return (
    <SimpleGrid minChildWidth="240px" spacing="2em">
      {(!data || data?.length === 0) && (
        <Warning
          header={"No Data Found !"}
          desc={"No data was found please try again later."}
        />
      )}
      {data.length > 0 &&
        data.map((e, i) => {
          console.log(e.rating.status)
          return e.rating.status ==="rated"?
            <Box>
              <MovieDetailCard key={i} data={e} />
            </Box>:<></>
          }
        )}
    </SimpleGrid>
  );
}

export default completedOrders;
