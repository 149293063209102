"use client";

import {
  Box,
  Container,
  Stack,
  Text,
  InputGroup,
  InputLeftElement,
  Input,
  Checkbox,
  VStack,
  Button,
  Heading,
  List,
  ListItem,
  StackDivider,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { FaUser } from "react-icons/fa";
import { getWalletInfo, setWallletInfo } from "../../api/user";

export default function Simple() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    wallet_name: "",
    wallet_address: "",
    wallet_phone_number: "",
    currency1: "",
    currency2: "",
  });
  const [isWalletInfoSet, setIsWalletInfoSet] = useState(false);
  const toast = useToast();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      console.log("walletInfo", data);
      await setWallletInfo(data);
      getWallet();
      setLoading(false);
      toast({
        title: "Wallet Info Set",
        status: "success",
        position: "top",
        isClosable: true,
      });
    } catch (error) {
      setLoading(false);
      console.error(error);
      toast({
        title: "Something Went Wrong",
        description:
          error?.message || error?.error?.message || "Contact Support",
        status: "error",
        position: "top",
      });
    }
  };

  const handleCheckboxChange = (e) => {
    const { name, value } = e.target;
    setData((prevData) => ({
      ...prevData,
      [name]: value, // Update the value to the selected one
    }));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setData({
      ...data,
      [name]: value,
    });
  };

  function getWallet() {
    try {
      setLoading(true);
      getWalletInfo().then((data) => {
        setData(data);
        setIsWalletInfoSet(data === false);
        setLoading(false);
        console.log("data", data);
      });
    } catch (error) {
      console.log(error);
      setLoading(false);
      toast({
        title: "Something Went Wrong",
        description: "Contact Support",
        status: "error",
        position: "top",
      });
    }
  }

  useEffect(() => {
    getWallet();
  }, []);

  return (
    <Container maxW={"2xl"} pt={"5rem"} color={"gray.300"}>
      <Stack spacing={{ base: 6, md: 10 }}>
        <Box as={"header"}>
          <Heading
            lineHeight={1.1}
            fontWeight={600}
            fontSize={{ base: "2xl", sm: "4xl", lg: "5xl" }}
            color={useColorModeValue("yellow.500", "yellow.300")}
          >
            Wallet Info
          </Heading>
        </Box>

        <Stack
          spacing={{ base: 4, sm: 6 }}
          direction={"column"}
          divider={
            <StackDivider
              borderColor={useColorModeValue("gray.200", "gray.600")}
            />
          }
        >
          <VStack spacing={{ base: 4, sm: 6 }}>
            <Text fontSize={"lg"}>
            Kindly be advised that it is imperative to refrain from divulging
              your personal information to any third party. Our company will
              never request sensitive details from you. We strongly urge you to
              exercise caution and safeguard your personal information to
              mitigate the risk of potential losses. Please note that our
              company will not assume responsibility for any issues arising from
              the unauthorized sharing of personal information.
            </Text>
          </VStack>
          <Box>
            <Text
              fontSize={{ base: "16px", lg: "18px" }}
              color={useColorModeValue("yellow.500", "yellow.300")}
              fontWeight={"500"}
              textTransform={"uppercase"}
              mb={"4"}
            >
              Product Details
            </Text>

            <List spacing={2} maxW={"70%"}>
              <ListItem>
                <InputGroup>
                  <InputLeftElement pointerEvents="none">
                    <FaUser color="gray" />
                  </InputLeftElement>
                  <Input
                    placeholder="User Name"
                    bg={"gray.300"}
                    border={0}
                    color={"gray.700"}
                    _placeholder={{
                      color: "gray.500",
                    }}
                    value={data.wallet_name}
                    name="wallet_name"
                    onChange={handleInputChange}
                    isReadOnly={!isWalletInfoSet}
                  />
                </InputGroup>
              </ListItem>

              <ListItem>
                <InputGroup>
                  <Input
                    placeholder="Wallet Address"
                    bg={"gray.300"}
                    border={0}
                    color={"gray.700"}
                    _placeholder={{
                      color: "gray.500",
                    }}
                    value={data.wallet_address}
                    name="wallet_address"
                    onChange={handleInputChange}
                    isReadOnly={!isWalletInfoSet}
                  />
                </InputGroup>
              </ListItem>

              <ListItem>
                <InputGroup>
                  <Input
                    placeholder="Wallet Phone Number"
                    bg={"gray.300"}
                    border={0}
                    color={"gray.700"}
                    _placeholder={{
                      color: "gray.500",
                    }}
                    value={data.wallet_phone_number}
                    name="wallet_phone_number"
                    onChange={handleInputChange}
                    isReadOnly={!isWalletInfoSet}
                  />
                </InputGroup>
              </ListItem>

              {/* Currency 1 - Single Selection */}
              <ListItem>
                {/* <Text fontSize="lg" mb={2}>
                  Select Currency 1:
                </Text> */}
                <Stack spacing={[1, 5]} direction={["column", "row"]}>
                  <Checkbox
                    value="TRC20"
                    name="currency1"
                    isChecked={data.currency1 === "TRC20"}
                    onChange={handleCheckboxChange}
                  >
                    TRC20
                  </Checkbox>
                  <Checkbox
                    value="ERC20"
                    name="currency1"
                    isChecked={data.currency1 === "ERC20"}
                    onChange={handleCheckboxChange}
                  >
                    ERC20
                  </Checkbox>
                </Stack>
              </ListItem>

              {/* Currency 2 - Single Selection */}
              <ListItem mt={5}>
                {/* <Text fontSize="lg" mb={2}>
                  Select Currency 2:
                </Text> */}
                <Stack spacing={[1, 5]} direction={["column", "row"]}>
                  <Checkbox
                    value="USDT"
                    name="currency2"
                    isChecked={data.currency2 === "USDT"}
                    onChange={handleCheckboxChange}
                  >
                    USDT
                  </Checkbox>
                  <Checkbox
                    value="USDC"
                    name="currency2"
                    isChecked={data.currency2 === "USDC"}
                    onChange={handleCheckboxChange}
                  >
                    USDC
                  </Checkbox>
                  <Checkbox
                    value="ETH"
                    name="currency2"
                    isChecked={data.currency2 === "ETH"}
                    onChange={handleCheckboxChange}
                  >
                    ETH
                  </Checkbox>
                  <Checkbox
                    value="BTC"
                    name="currency2"
                    isChecked={data.currency2 === "BTC"}
                    onChange={handleCheckboxChange}
                  >
                    BTC
                  </Checkbox>
                </Stack>
              </ListItem>
            </List>
          </Box>
        </Stack>
        {isWalletInfoSet && (
          <Button onClick={handleSubmit} isLoading={loading}>
            Submit
          </Button>
        )}
      </Stack>
    </Container>
  );
}
