"use client";

import React from "react";
import {
  Box,
  Stack,
  HStack,
  Heading,
  Text,
  VStack,
  useColorModeValue,
  List,
  ListItem,
  ListIcon,
  Button,
} from "@chakra-ui/react";
import { FaCheckCircle } from "react-icons/fa";

function PriceWrapper({ children }) {
  return (
    <Box
      mb={4}
      shadow="base"
      borderWidth="1px"
      alignSelf={{ base: "center", lg: "flex-start" }}
      borderColor={useColorModeValue("gray.200", "gray.500")}
      borderRadius={"xl"}
    >
      {children}
    </Box>
  );
}

export default function ThreeTierPricing() {
  return (
    <Box py={12}>
      <VStack spacing={2} textAlign="center">
        <Heading as="h1" fontSize="4xl"  >
          Our Membership Plans
        </Heading>
        <Text
          fontSize="medium"
          fontWeight="300"
          color={"gray.300"}
          maxW={"2xl"}
        >
          Holly Movies Membership Upgrade for Master: There are additional
          benefits. If you’d like to learn more, please feel free to contact our
          customer service!
        </Text>
      </VStack>
      <Stack
        direction={{ base: "column", md: "row" }}
        textAlign="center"
        justify="center"
        spacing={{ base: 4, lg: 10 }}
        py={10}
      >
        <PriceWrapper>
          <Box py={4} px={12}>
            <Text fontWeight="500" fontSize="2xl" color={"gray.300"}>
              Elite
            </Text>
            <HStack justifyContent="center">
              <Text fontSize="3xl" fontWeight="600" color={"gray.300"}>
                $
              </Text>
              <Text fontSize="5xl" fontWeight="900" color={"gray.300"}>
                1000
              </Text>
            </HStack>
          </Box>
          <VStack
            bg={useColorModeValue("gray.50", "gray.700")}
            py={4}
            borderBottomRadius={"xl"}
          >
            <List spacing={3} textAlign="start">
              <ListItem>
                <ListIcon as={FaCheckCircle} color="green.500" />
                Boosting Days
                <Text fontSize="large" fontWeight="900">
                  50
                </Text>
              </ListItem>
              <ListItem>
                <ListIcon as={FaCheckCircle} color="green.500" />
                commission
                <Text fontSize="large" fontWeight="900">
                  0.8% - 6%
                </Text>
              </ListItem>
            </List>
            <Box w="80%" pt={7}>
              <Button w="full" colorScheme="red" variant="outline">
                Start trial
              </Button>
            </Box>
          </VStack>
        </PriceWrapper>

        <PriceWrapper>
          <Box position="relative">
            <Box py={4} px={12}>
              <Text fontWeight="500" fontSize="2xl" color={"gray.300"}>
                Expert
              </Text>
              <HStack justifyContent="center">
                <Text fontSize="3xl" fontWeight="600" color={"gray.300"}>
                  $
                </Text>
                <Text fontSize="5xl" fontWeight="900" color={"gray.300"}>
                  2000
                </Text>
              </HStack>
            </Box>
            <VStack
              bg={useColorModeValue("gray.50", "gray.700")}
              py={4}
              borderBottomRadius={"xl"}
            >
              <List spacing={3} textAlign="start" px={12}>
                <ListItem>
                  <ListIcon as={FaCheckCircle} color="green.500" />
                  Boosting Days
                  <Text fontSize="large" fontWeight="900">
                    60
                  </Text>
                </ListItem>
                <ListItem>
                  <ListIcon as={FaCheckCircle} color="green.500" />
                  Commission
                  <Text fontSize="large" fontWeight="900">
                    0.9% - 7%
                  </Text>
                </ListItem>
              </List>
              <Box w="80%" pt={7}>
                <Button w="full" colorScheme="red">
                  Start trial
                </Button>
              </Box>
            </VStack>
          </Box>
        </PriceWrapper>
        <PriceWrapper>
          <Box py={4} px={12}>
            <Text fontWeight="500" fontSize="2xl" color={"gray.300"}>
              Master
            </Text>
            <HStack justifyContent="center">
              <Text fontSize="3xl" fontWeight="600" color={"gray.300"}>
                $
              </Text>
              <Text fontSize="5xl" fontWeight="900" color={"gray.300"}>
                4000
              </Text>
            </HStack>
          </Box>
          <VStack
            bg={useColorModeValue("gray.50", "gray.700")}
            py={4}
            borderBottomRadius={"xl"}
          >
            <List spacing={3} textAlign="start" px={12}>
              <ListItem>
                <ListIcon as={FaCheckCircle} color="green.500" />
                Boosting of days
                <Text fontSize="large" fontWeight="900">
                  70
                </Text>
              </ListItem>
              <ListItem>
                <ListIcon as={FaCheckCircle} color="green.500" />
                Commission
                <Text fontSize="large" fontWeight="900">
                  1% - 8%
                </Text>
              </ListItem>
            </List>
            <Box w="80%" pt={7}>
              <Button w="full" colorScheme="red" variant="outline">
                Start trial
              </Button>
            </Box>
          </VStack>
        </PriceWrapper>
      </Stack>
    </Box>
  );
}
