import React, { useState } from "react";
import { Container, useToast } from "@chakra-ui/react";
import Tabs from "./components/tabs";
import { useEffect } from "react";
import { orderHistory } from "../../api/order";
function Index() {
  const toast = useToast();
  const [data,setData] = useState({})
  const [loading,setLoading] = useState(false)
  useEffect(()=>{
    setLoading(true);
    let res
    try {
      orderHistory().then(res=>{
        setData(res.currentSession.ratedMovies);
      });
    } catch (error) {
      setLoading(false);
      toast({
        title: "Error While Loading Data",
        description: error.message,
        status: "error",
        position: "top",
      });
    }
  },[])
  return (
    <Container alignContent={"flex-start"} maxW={"100%"} >
      {loading && <Tabs data = {data} />}
    </Container>
  );
}

export default Index;
